import Editor from "@tinymce/tinymce-vue"
import _ from "underscore";
export default {
    name: "MaterialProjectAdd",
    components: {
        'tiny-editor': Editor
    },
    data() {
        let checkLink = (rule, value, callback) => {
            if (!/^(http:\/\/|https:\/\/).*/.test(value)) {
                callback(new Error('输入的链接格式不正确，请重新输入！'))
            } else {
                callback();
            }
        };
        return {
            headerObj: {
                'Authorization': localStorage.getItem('token'),
            },
            eDialogVisible: false,
            rDialogVisible: false,
            evaluate_form: {
                index: null,
                content: '',
                evaluate: '',
                score: ''
            },
            rules: {
                index: [
                    { required: true, message: '请输入评价序号', trigger: 'blur' },
                    { required: true, message: '请输入评价序号', trigger: 'change' },
                    { type: 'number', message: '序号必须为正整数', trigger: 'blur' },
                    { type: 'number', message: '序号必须为正整数', trigger: 'change' }
                ],
                content: [
                    { required: true, message: '请输入评价内容', trigger: 'blur' },
                    { required: true, message: '请输入评价内容', trigger: 'change' }
                ],
                evaluate: [
                    { required: true, message: '请输入评价要求', trigger: 'blur' },
                    { required: true, message: '请输入评价要求', trigger: 'change' }
                ],
                score: [
                    { required: true, message: '请输入评价分值', trigger: 'blur' },
                    { type: 'number', message: '评分分值必须为正整数', trigger: 'blur' }
                ]
            },
            link_form: {
                name: '',
                url: ''
            },
            link_rules: {
                name: [
                    { required: true, message: '请输入链接名称', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在2-10个字符', trigger: 'blur' }
                ],
                link: [
                    { required: true, message: '请输入链接网址', trigger: 'blur' },
                    { validator: checkLink, trigger: 'blur' }
                ]
            },
            form: {
                project_title: '',
                resource_class_id: [],
                project_duration: null,
                project_difficulty: null,
                project_attrs: [{
                        name: '项目简介',
                        value: ''
                    },
                    {
                        name: '项目目标',
                        value: ''
                    },
                    {
                        name: '项目要求',
                        value: ''
                    },
                    {
                        name: '待定栏目一',
                        value: ''
                    },
                    {
                        name: '待定栏目二',
                        value: ''
                    }
                ],
                project_task: {
                    name: '项目任务',
                    value: ''
                },
                project_evaluate: [],
                project_knowledge_link: [],
                project_sucai: [],
                project_apps_id: [],
                project_ability: ''
            },
            options: [],
            class_props: {
                label: 'rc_name',
                value: 'rc_id'
            },
            tiny_init: {
                menubar: false,
                height: 800,
                plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                toolbar: [
                    'undo redo | styleselect | bold fontselect italic underline strikethrough lineheight removeformat|fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist',
                    '|image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                ],
                image_advtab: true,
                language: 'zh_CN',
                images_upload_handler: function(blobInfo, success, failure, progress) {
                    var xhr, formData;
                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open('POST', 'project/tinyImgUpload');
                    xhr.setRequestHeader("Authorization", localStorage.getItem('token'));

                    xhr.upload.onprogress = function(e) {
                        progress(e.loaded / e.total * 100);
                    }

                    xhr.onload = function() {
                        var json;
                        if (xhr.status == 403) {
                            failure('HTTP Error: ' + xhr.status, { remove: true });
                            return;
                        }
                        if (xhr.status < 200 || xhr.status >= 300) {
                            failure('HTTP Error: ' + xhr.status);
                            return;
                        }
                        json = JSON.parse(xhr.responseText);
                        if (!json || typeof json.location != 'string') {
                            failure('Invalid JSON: ' + xhr.responseText);
                            return;
                        }
                        success(json.location);
                    };

                    xhr.onerror = function() {
                        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                    }

                    formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    xhr.send(formData);
                }
            },
            fileList: [],
            material_list: [],
            material_type: null,
            course_list: [],
            course_id: [],
            resource_list: [],
            resource_id: null,
            dialog_status: null,
            preview_src: null,
            video_src: null,
            evaluate_form_index: null,
            course_attr: {
                label: 'name',
                value: 'id'
            },
            app_lists: [],
            application_status: false,
            app_tmp_array: [],
            apps_selected: [],
            edit_key: null,
            id: null
        }
    },
    created() {
        this.projectClasses();
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.formEditStatus();
        }
    },
    computed: {
        isAppOrLink() {
            if (this.material_type === null) {
                return false;
            }
            else return [6, 7].indexOf(Number(this.material_type)) !== -1;
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        checkPjTitle() {
            let param = {
                project_title: this.form.project_title
            }
            if (this.id) {
                param.id = this.id;
            }
            return new Promise((resolve, reject) => {
                this.$http.axiosGetBy(this.$api.checkProjectName, param, (res) => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                })
            })

        },
        formEditStatus() {
            this.$http.axiosGetBy(this.$api.getProjectDetail, { id: this.id}, (res) => {
                if (res.code === 200) {
                    this.form = res.data;
                    if (this.form.project_sucai.length > 0) {
                        this.form.project_sucai.map((item) => {
                            let file_item = {
                                name: item.file_name,
                                url: item.file_path
                            }
                            this.fileList.push(file_item);
                        });
                    }
                    if (this.form.app_lists.length > 0) {
                        this.form.app_lists.map((item) => {
                            let app = {
                                app_name: item.app_name,
                                logo: {
                                  src: item.logo
                                },
                                home_link: item.home_link,
                                shop_link: item.shop_link,
                                teacher_link: item.teacher_link
                            }
                            this.apps_selected.push(app);
                        });
                    }
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            });

        },
        editAct(key) {
            this.edit_key = key;
        },
        editDone() {
            this.edit_key = null;
        },
        submitEform(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let evaluate_obj = Object.assign({}, this.evaluate_form);
                    if (this.evaluate_form_index !== null) {
                        this.$set(this.form.project_evaluate, this.evaluate_form_index, evaluate_obj);
                        this.evaluate_form_index = null;
                    } else {
                        this.form.project_evaluate.push(evaluate_obj);
                    }
                    this.$refs[formName].resetFields();
                    this.eDialogVisible = false;
                } else {
                    return false;
                }
            })
        },
        editEvaluete(index, row) {
            row = {...row, index: Number(row.index), score: Number(row.score)};
            this.evaluate_form = {...row };
            this.evaluate_form_index = index;
            this.eDialogVisible = true;
        },
        deleteEvaluete(index, row) {
            this.$confirm('此操作将永久删除该评价，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.form.project_evaluate.splice(index, 1);
            }).catch(() => {
                this.$message.info('已取消删除');
            });
        },
        handleRemove(file, fileList) {
            this.fileList = _.filter(fileList, (item) => {
                return item.uid !== file.uid;
            })
        },
        courseList() {
            this.$http.axiosGet(this.$api.getCourseList, (res) => {
                if (res.code === 200) {
                    let array = [];
                    if (res.data.length > 0) {
                        res.data.map((item) => {
                            let value = {
                                id: item.course_id,
                                name: item.course_name
                            };
                            if (item.course_content && item.course_content.length > 0) {
                                value.children = [];
                                item.course_content.map((_it, _i) => {
                                    let _chapter = {
                                        id: _i,
                                        name: _it.name
                                    }
                                    if (_it.children && _it.children.length > 0) {
                                        _chapter.children = [];
                                        _it.children.map((_node, _node_index) => {
                                            let node_item = {
                                                id: { index: _node_index, value: _node.children },
                                                name: _node.name
                                            };
                                            _chapter.children.push(node_item);
                                        });

                                    }
                                    value.children.push(_chapter);
                                });
                            }
                            array.push(value);
                        });
                    }
                    this.course_list = array;
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        /*beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },*/
        beforeMUpload(file) {
            let tmp = file.name.split('.');
            let extension = tmp[tmp.length - 1];
            let ext_allowed = 'mp4,avi,pdf,doc,docx,xls,xlsx,ppt,pptx,jpg,png,zip,rar';
            let ext_allowed_arr = ext_allowed.split(',');
            if (ext_allowed_arr.indexOf(extension) === -1) {
                this.$message({
                    type: 'error',
                    message: '上传文件类型不支持',
                    duration: 1000,
                });
                return false;
            }
        },
        handleSuccess(res, file) {
            if (res.code === 200) {
                this.fileList.push(file);
            } else {
                this.$message({
                    type: 'warning',
                    message: res.msg,
                    duration: 1000,
                    onClose: () => {
                        this.handleRemove(file, this.fileList);
                    }
                });
            }
        },
        initResourceDialog() {
            this.assortMaterialList();
            this.courseList();
        },
        assortMaterialList() {
            this.$http.axiosGet(this.$api.getMatingTypeList, (res) => {
                if (res.code === 200) {
                    this.material_list = res.list;
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err)
            })
        },
        formatList(list) {
            let array = [];
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                if (item.children && item.children.length > 0) {
                    let value = {
                        rc_id: item.rc_id,
                        rc_name: item.rc_name,
                        children: this.formatList(item.children)
                    }
                    array.push(value);
                } else {
                    let value = {};
                    value.rc_id = item.rc_id;
                    value.rc_name = item.rc_name;
                    array.push(value);
                }
            }
            return array;
        },
        projectClasses() {
            this.$http.axiosGetBy(this.$api.resourceClassList, { type: 4 }, (res) => {
                if (res.code === 200) {
                    let list = this.formatList(res.list);
                    this.options = list;
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            });
        },
        projectClassesHandler(value) {
            // console.log(value);
        },
        addAppDialog() {
            this.rDialogVisible = true;
            this.material_type = 6;
            this.application_status = true;
            this.app_tmp_array = this.form.project_apps_id;
            this.applicationsList();
        },
        addKnowledgeLink() {
            this.rDialogVisible = true;
        },
        handleChangeCourse(val) {
            let tmp_val = val[val.length - 1];
            let course_id = val[0];
            let course_chapter = val[1];
            let course_node = tmp_val.index;
            let course_arr = [];
            if (tmp_val.value.length > 0) {
                tmp_val.value.map((item) => {
                    let val = {
                        material_type_id: this.material_type,
                        type: item.type,
                        id: Number(item.id)
                    };
                    course_arr.push(val);
                });
            }
            let param = {
                course_arr,
                material_type_id: this.material_type,
                course_id,
                course_node,
                course_chapter
            }
            this.$http.axiosPost(this.$api.getResource, param, (res) => {
                if (res.code === 200) {
                    this.resource_list = res.data;
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err)
            })
        },
        handleMaterialType(val) {
            this.material_type = val;
            if (this.isAppOrLink) {
                if (Number(this.material_type) === 6) {
                    this.applicationsList();
                }
                this.preview_src = null;
                this.video_src = null;
            }
        },
        handleChangeResource(val) {
            let resource_item = _.find(this.resource_list, { resource_id: val });
            let file_arr = resource_item.file_name.split('.');
            let ext = file_arr[file_arr.length - 1];
            if (ext.toLowerCase() === 'mp4') {
                this.video_src = resource_item.file_preview_path;
                this.preview_src = null;
            } else {
                this.preview_src = resource_item.file_preview_path;
                this.video_src = null;
            }
        },
        resetResourceDialog() {
            this.preview_src = null;
            this.video_src = null
            this.material_type = null;
            this.resource_id = null;
            this.course_id = [];
            this.course_list = [];
            this.application_status = false;
        },
        applicationsList() {
            this.$http.axiosGetBy(this.$api.app_showApp, {type: 'all',parent_id: 0}, (res) => {
                if (res.code === 200) {
                    this.app_lists = res.data;
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        appIdsSelected(id) {
            if (this.app_tmp_array.indexOf(id) !== -1) {
                this.app_tmp_array = _.filter(this.app_tmp_array, (item) => {
                    return Number(item) !== Number(id);
                })
            } else {
                this.app_tmp_array.push(id);
            }
        },
        appIdhasSelected(id) {
            if (this.app_tmp_array.indexOf(id) !== -1) {
                return 'selected';
            } else {
                return '';
            }
        },
        rFormSubmit() {
            switch (this.material_type) {
                case 6:
                    //设置应用
                    this.form.project_apps_id = [].concat(this.app_tmp_array);
                    this.apps_selected = _.filter(this.app_lists, (item) => {
                        if (this.form.project_apps_id.indexOf(item.id) !== -1) {
                            return item;
                        }
                    });
                    this.app_tmp_array = [];
                    this.rDialogVisible = false;
                    break;
                case 7:
                    //设置链接
                    this.$refs['link_form'].validate((valid) => {
                        if (valid) {
                            let form_value = Object.assign({}, this.link_form);
                            form_value.type = 7;
                            this.form.project_knowledge_link.push(form_value);
                            this.$refs['link_form'].resetFields();
                            this.rDialogVisible = false;
                        }
                    })
                    break;
                default:
                    //默认设置配套材料
                    let link_form = {
                        id: this.resource_id,
                        type: this.material_type
                    };
                    let link_item = _.find(this.resource_list, { resource_id: link_form.id })
                    link_form.name = link_item.file_name;
                    link_form.url = link_item.file_preview_path;
                    this.form.project_knowledge_link.push(link_form);
                    this.rDialogVisible = false;
                    break;
            }
        },
        typeClass(name, type) {
            if (Number(type) === 7) {
                return 'animation-link';
            }
            let tmp_array = name.split(".");
            let ext = tmp_array[tmp_array.length - 1];
            let classname = '';
            switch (ext) {
                case 'docx':
                    classname = 'textbook';
                    break;
                case 'ppt':
                    classname = 'coureware';
                    break;
                case 'mp4':
                    classname = 'micro-lesson';
                    break;
                case 'pdf':
                    classname = 'person-resource';
                    break;
                default:
                    break;
            }
            return classname;
        },
        removeLink(key) {
            this.form.project_knowledge_link.splice(key, 1);
        },
        removeApp(key) {
            let item = this.apps_selected[key];
            this.form.project_apps_id = _.filter(this.form.project_apps_id, (_it) => {
                return _it !== item.id;
            });
            this.apps_selected.splice(key, 1);
        },
        saveProject() {
            try {
                if (this.form.project_title.length === 0) {
                    throw new Error('请输入项目标题');
                }
                if (this.form.project_title.length > 24) {
                    throw new Error('项目标题长度不能超过24个字符');
                }
                if (this.form.resource_class_id.length === 0) {
                    throw new Error('项目分类必选');
                }
                if (!this.form.project_duration) {
                    throw new Error('项目课时必填');
                }
                if (!this.form.project_difficulty) {
                    throw new Error('项目难度必填');
                }
                if (this.form.project_task.value.length === 0) {
                    throw new Error('项目任务必填');
                }
                /*if (/<p>(&nbsp; |&nbsp;)+<\/p>/.test(this.form.project_task.value)) {
                    throw new Error('项目任务不能为空');
                }*/
                this.checkPjTitle().then((res) => {
                    if (res.code === 400) {
                        throw new Error(res.msg)
                    }
                });
            } catch (error) {
                this.$message.warning(error.message);
                return;
            }
            this.form.project_sucai = [];
            if (this.fileList.length > 0) {
                this.fileList.map((_item) => {
                    let value = {};
                    if (_item.response) {
                        value.file_name = _item.response.data.name;
                        value.file_path = _item.response.data.path;
                    } else {
                        value.file_name = _item.name;
                        value.file_path = _item.url;
                    }
                    this.form.project_sucai.push(value);
                });
            }
            if (this.id) {
                this.form.id = this.id;
            }
            let form = Object.assign({}, this.form);
            if (this.form.project_knowledge_link.length > 0) {
                let knowledge_links = [];
                this.form.project_knowledge_link.map((_val) => {
                    let tmp = {};
                    if (Number(_val.type) === 7) {
                        tmp.type = _val.type;
                        tmp.url = _val.url;
                        tmp.name = _val.name;
                    } else {
                        tmp.id = _val.id;
                        tmp.type = _val.type;
                    }
                    knowledge_links.push(tmp);
                });
                form.project_knowledge_link = knowledge_links;
            }
            form.resource_class_id = form.resource_class_id[form.resource_class_id.length - 1];
            this.$http.axiosPost(this.$api.saveProject, form, (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            this.$router.push('/admin/index');
                        }
                    });
                }
            }, (error) => {
                this.$common.axiosErrorMsg(error);
            });
        }
    },
}